/**
 * Sliders
 *
 * Gestion des sliders
 * bug on rezise see -> https://github.com/kenwheeler/slick/issues/542#issuecomment-168658956
 */
require('slick-carousel');

$(document).ready(function () {

  let defaultSliders = [
    '.cross-content-slider',  // slider recettes suggérées - Fiche recette
    '.filters-slider',        // slider filtres - Liste produits
    '.tags-slider',           // slider tags - Liste articles
    '.search-slider',         // sliders - Page résultats de recherche
  ];

  /**
   * Slider par défaut (déclenché en mobile et désactivé en desktop)
   */
  defaultSliders.forEach(slider => $(slider).slick({
      responsive: [
      {
        breakpoint: 9999,
        settings: "unslick" // désactive le slider
      },
      {
        breakpoint: 768,
        settings: {
          variableWidth: true,
          dots: false,
          arrows: false,
          infinite: false
        }
      }
    ]
  }));

    /**
     * Slider produit - Fiche recette
     */
    $('.recipe-products-slider').slick({
      dots: true,
      responsive: [
          {
              breakpoint: 768,
              settings: {
                  variableWidth: true,
                  dots: false,
                  arrows: false,
              }
          }
      ]
    });

  /**
   * Slider produits - Homepage
   */
    $('[data-role="home-product-slick"]').slick({
        fade: true,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    variableWidth: true,
                    dots: false,
                    arrows: false,
                    infinite: false,
                    fade: false
                }
            }
        ]
    });

    /**
     * Slider étapes - Fiche recette
     */
    $('.steps-slider').slick({
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    variableWidth: true,
                    dots: false,
                    arrows: false,
                    infinite: false
                }
            }
        ]
    });

    /**
     * Slider produits - Homepage
     */
    $('[data-role="custom-block-slider"]').slick({
        fade: true,
        cssEase: 'linear',
        adaptiveHeight: true,
        dots: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    variableWidth: true,
                    dots: true,
                    arrows: false,
                    infinite: false,
                    fade: false
                }
            }
        ]
    });
});
